<template>
  <div class="fluid rounded-lg p-2 shadow-lg" id="roles">
    <div class="table-responsive">
      <b-table
        :items="roles"
        :fields="parentFields"
        show-empty
        responsive
        striped
        hover
        class="bg-white cursor-icon short-table"
        style="width: 80%; margin: 0 auto"
        :per-page="query.per_page"
      >
        <template #cell(id)="row">
          <div
            class="`p-1"
          >
            <p>
              {{ row.index + 1 }}
            </p>
          </div>
        </template>
        <template #cell(name)="row">
          <p class="text-capitalize">
            {{ row.item.name === 'customer' ? 'client' : row.item.name }}
          </p>
        </template>
        <template #cell(action)="row">
          <div>
            <b-link
              v-if="$can('view', 'Role Permission')"
              :to="`/roles/view/${row.item.id}`"
              class="btn btn-info btn-sm"
            >
              View</b-link
            >
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  BLink,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { toast } from "@/utils/toast";

export default {
  components: {
    vSelect,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BLink,
  },

  data() {
    return {
      homeLink: false,
      roles: [],
      nameState: null,
      shift: {
        name: "",
      },
      query: {
        keyword: "",
        page: 1,
        per_page: 20,
      },
      parentFields: [
        {
          key: "id",
          label: "id",
          sortable: true,
        },
        {
          key: "name",
          sortable: true,
          label: "name",
        },
        {
          key: "action",
          label: "action",
        },
      ],
    };
  },
  created() {
    this.getRoles();
  },
  methods: {
    getRoles() {
      this.$store.dispatch("role/getRoles", this.query).then((response) => {
        this.roles = response.data;
      });
    },
  },
};
</script>

<style scoped>
.active-success {
  border-left: 4px solid #50c878;
  margin-right: 5px;
}
.active-danger {
  border-left: 4px solid #ff4f5d;
  margin-right: 5px;
}
.add-btn {
  border-color: white !important;

  /* Gradient */

  background: linear-gradient(#01185e 0%, #264296 44.92%, #7190ef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

th:last-child {
  text-align: end !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
